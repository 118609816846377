import React, { FormEvent, FunctionComponent } from 'react'
import { Checkbox } from '@blueprintjs/core'

interface CategoryValue {
  display: string
  id: number,
  children: Array<any>
}

export type CheckedIdList = Array<number>

export type CategoryOptions = Array<CategoryValue>

interface CategoryProps {
  options: CategoryOptions,
  categories: CheckedIdList,
  onChange: (checkedList: CheckedIdList) => any
}

const Categories: FunctionComponent<CategoryProps> = ({ options, onChange, categories }) => {
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const value = Number(e.currentTarget.value)
    onChange(e.currentTarget.checked 
      ? [...categories, value] 
      : categories.filter(c => c !== value)
    )
  }

  const checkBoxList = options.map(
    ({id, display, children}) => (
      <div key={id} className='checkboxes'>
        <h3>{display}</h3>
        {children.map((child) => (
          <Checkbox
            inline={true}
            checked={categories.includes(child.id)}
            onChange={handleChange}
            key={child.id}
            value={child.id}
            label={child.display} />
        ))}
      </div>
    )
  )
  
  return (
    <>
      {checkBoxList}
    </>
  )
}

export default Categories