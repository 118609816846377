import React, { FormEvent, FunctionComponent } from 'react'
import { Radio, RadioGroup } from '@blueprintjs/core'

interface typeValue {
  name: string
  display: string
}

interface typeProps {
  value: string,
  onChange: (value: string) => any
}

const options: Array<typeValue> = [{ name: 'video', display: '비디오'}, { name: 'offline', display: '오프라인'}]

const ContentTypes: FunctionComponent<typeProps> = ({ value, onChange }) => {
  // const [options, setOptions] = useState<typeValue[]>([])
  
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value)
  }

  return (
    <RadioGroup
      inline={true}
      label="컨텐츠 타입"
      onChange={handleChange}
      selectedValue={value}
    >
      {options.map(({name, display}) => (
        <Radio
          key={name}
          label={display}
          value={name}/>
      ))}
    </RadioGroup>
  )
}

export default ContentTypes