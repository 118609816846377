import React, { FormEvent, FunctionComponent, useCallback } from 'react'
import { Radio, RadioGroup } from '@blueprintjs/core'

interface ServiceValue {
  name: string
  display: string
  id: number
}

export type ServiceOptions = Array<ServiceValue>

interface ServiceProps {
  value: string,
  onChange: (value: string) => any,
  options: ServiceOptions
}

const Services: FunctionComponent<ServiceProps> = ({ value, onChange, options }) => {

  const handleChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value)
  }, [onChange])

  return (
    <RadioGroup
      inline={true}
      label="서비스"
      onChange={handleChange}
      selectedValue={value || 'weport'}
    >
      {options.map(({name, display, id}) => (
        <Radio
          key={id}
          label={display}
          value={name}/>
      ))}
    </RadioGroup>
  )
}

export default Services