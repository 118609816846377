import React, { FunctionComponent, useState, useEffect, useContext } from 'react'
import {
  Navbar,
  NavbarGroup,
  Button,
  Alignment,
  Classes,
  Intent,
} from '@blueprintjs/core'
import styled from 'styled-components'
import { WixDataLoader } from '@/components/loader'
import { setWixData, triggerSettingsUpdatedEvent } from '@/components/wix'
import Services, { ServiceOptions } from './components/services'
import Categories, { CategoryOptions, CheckedIdList } from './components/categories'
import ContentTypes from './components/type'
import { AxiosContext } from '@/components/context'

const Main = styled.div`
  margin: 15px 10px;
`
interface EditorProps {
  initialData: {
    service?: string
    contentType?: string
    categories?: number[]
  }
}

const Editor: FunctionComponent<EditorProps> = ({ initialData }) => {
  const [service, setService] = useState(initialData.service || 'weport')  
  const [contentType, setContentType] = useState(initialData.contentType || 'video')
  const [categories, setCategory] = useState(initialData.categories || [])
  const [categoryOptions, setCategoryOptions] = useState<CategoryOptions>([])
  const [serviceOptions, setServiceOptions] = useState<ServiceOptions>([])
  const axios = useContext(AxiosContext)

  const handleServiceChange = (serviceName: string) => {
    setService(serviceName)
    setCategory([])
  }

  const handleCategoryChange = (checkeds: CheckedIdList) => {
    setCategory(checkeds)
  }

  const handleContentTypeChange = (type: string) => {
    setContentType(type)
    setCategory([])
  }

  const handleSave = () => {
    setWixData(
      { categories, service, contentType },
      result => {
        triggerSettingsUpdatedEvent({ result })
      },
      reason => {},
    )
  }  

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get('/service/services?service=weport')
      setServiceOptions(data.results)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(`/content/categories/${service}/${contentType}?service=weport`)
      setCategoryOptions(data.results)
    }

    fetchData()
  }, [service, contentType])

  return (
    <>
      <Navbar>
        <NavbarGroup align={Alignment.RIGHT}>
          <Button
            className={Classes.MINIMAL}
            intent={Intent.PRIMARY}
            text="Save"
            onClick={handleSave}
          />
        </NavbarGroup>
      </Navbar>
      <Main>
        <Services
          value={service}
          options={serviceOptions}
          onChange={handleServiceChange}/>
        <ContentTypes 
          value={contentType}
          onChange={handleContentTypeChange}
        />
        <Categories
          categories={categories}
          options={categoryOptions}
          onChange={handleCategoryChange}/>
      </Main>
    </>
  )
}
const SettingsApp: FunctionComponent = () => {
  return (
    <WixDataLoader 
      render={data => <Editor initialData={data || {}} />} 
    />
  )
}

export default SettingsApp
