import React, { useEffect } from 'react'
import App from './App'

export default () => {
  useEffect(() => {
    require('@blueprintjs/core/lib/css/blueprint.css')
    require('@blueprintjs/icons/lib/css/blueprint-icons.css')
  })

  return <App />
}
